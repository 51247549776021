import React from "react";
import AboutOurProducts from "../components/partials/about-our-products";
import GoogleReviews from "../components/sections/google-reviews";
import Guarantee from "../components/sections/guarantee";
import SimpleProduct from "../components/simple-product";

const linersPage = () => {
  return (
    <>
      <SimpleProduct sku="window-well-lock" />
      <Guarantee />
      <AboutOurProducts />
      <GoogleReviews />
    </>
  );
};
export default linersPage;
